/*------------------------------------------------------------------------------
  Template Name: Findup – Directory & Listing HTML5 Template
-------------------------------------------------------------------------------*/

@import "header";
@import "content";

@import "~antd/dist/antd.less";

.xvia-main-app,
#xvia-main {
  height: 100%;
}

.xvia-main-app {
  display: flex;
  flex-direction: column;
}

